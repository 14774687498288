import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'src/helpers/authHelpers';
import store from 'src/store/store';
import { IUserSlice } from 'src/store/user/userSlice.contracts';

export const updateUserCrops = createAsyncThunk(
  'user/updateCrops',
  async (params: {crops: string[]}, { rejectWithValue }) => {
    try {
      const user: IUserSlice = await store.getState().user;

      return {
        ...user,
        preferences: {
          crops: params.crops
        }
      } as IUserSlice;
    } catch (error: any) {
      toast.error(getErrorMessage(error.code));
      return rejectWithValue(error.message);
    }
  }
);
