import { Stack, Typography } from '@mui/material';
import {
	GridActionsCellItem,
	GridAlignment,
	GridCellParams,
	GridColDef,
	GridColumnHeaderParams,
	GridRowParams,
} from '@mui/x-data-grid';
import { Icon, IconProps } from 'features';

export interface IHeaderTypes {
	title: string;
	keyName: string;
	width: string;
}

export interface IAction {
	label: string;
	icon?: IconProps['icon'] | any;
	showInMenu?: boolean;
	onClick?: (e: any) => void;
	styles?: any;
	iconStyles?: any;
	disabled?: (params: any) => boolean;
}

export interface ICustomActions {
	getAction: (params: any) => IAction;
}

export interface IHeader {
	field: string;
	headerName?: string | null;
	icon?: IconProps['icon'];
	minWidth?: number;
	maxWidth?: number;
	align?: GridAlignment;
	headerAlign?: GridAlignment;
	flex?: number;
	valueGetter?: (params: any) => any;
	renderCell?: (params: GridCellParams) => any;
	renderHeader?: (params: GridColumnHeaderParams) => JSX.Element;
	headerInfo?: JSX.Element | JSX.Element[];
}

function createHeaders(
	headers: IHeader[],
	actions?: IAction[] | ICustomActions[]
): GridColDef[] {
	// const minWidth = 470 / headers.length;

	const gridHeaders: GridColDef[] = headers.map((header) => {
		const gridColDef: GridColDef = {
			field: header.field,
			minWidth: header.minWidth,
			maxWidth: header.maxWidth,
			sortable: false,
			align: header.align ? header.align : 'left',
			headerAlign: header.headerAlign ? header.headerAlign : 'left',
			flex: header.flex ? header.flex : 1,
			renderHeader: () => {
				return headerDisplay(
					header.headerName ?? '',
					header.icon,
					header.headerInfo
				);
			},
			renderCell: header.renderCell,
			valueGetter: header.valueGetter,
		};
		return gridColDef;
	});

	if (actions && actions.length > 0) {
		gridHeaders.push({
			field: 'actions',
			type: 'actions',
			flex: 0.4,
			minWidth: 30,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			getActions: (params) => createActions(actions, params),
		});
	}

	return gridHeaders;
}

function createActions(
	actions: IAction[] | ICustomActions[],
	params: GridRowParams<any>
): JSX.Element[] {
	let actionList: IAction[] = [];
	if (actions.length && 'getAction' in actions[0]) {
		(actions as ICustomActions[]).map((action) => {
			const { getAction } = action;
			actionList.push(getAction(params));
		});
	} else {
		actionList = actions as IAction[];
	}

	const gridActions: JSX.Element[] = actionList.map((action) => {
		const { icon, label, showInMenu, onClick, styles, iconStyles, disabled } =
			action;
		const actionDisabled = disabled !== undefined ? disabled(params) : false;
		return (
			<GridActionsCellItem
				label={label}
				icon={icon ? <Icon icon={icon} sx={{ ...iconStyles }} /> : <></>}
				key=""
				onClick={onClick ? () => onClick(params) : () => false}
				showInMenu={showInMenu}
				sx={{
					display: 'flex',
					flexDirection: 'row-reverse',
					justifyContent: 'center',
					m: 1,
					borderRadius: '20px',
					...styles,
				}}
				size="small"
				disabled={actionDisabled}
			/>
		);
	});

	return gridActions;
}

function headerDisplay(
	header: string,
	icon?: IconProps['icon'],
	headerInfo?: JSX.Element | JSX.Element[]
) {
	return (
		<Stack
			display="flex"
			alignContent="right"
			direction="row"
			gap={1}
			whiteSpace="pre-wrap"
			alignItems={'center'}
		>
			{icon && <Icon icon={icon} />}
			<Typography component="div" variant="bodyBold">
				{header}
			</Typography>
			{headerInfo}
		</Stack>
	);
}

export { createActions, createHeaders };
