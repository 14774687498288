import { IconProps } from 'features';
import pages from 'src/router/routes';
import { Access } from 'src/store/user/userSlice.contracts';

export type SidebarTitleProps = {
	title: string;
	icon: IconProps['icon'];
	path: string;
	children?: SidebarTitleProps[];
	open?: boolean;
	casl?: { action: string; access: Access | string };
};

export const SidebarTitles: SidebarTitleProps[] = [
	{
		title: 'My Account',
		icon: 'UilEstate',
		open: false,
		path: '',
		casl: { action: 'view', access: Access[Access.MyAccount] },
		children: [
			{
				title: 'Overview',
				icon: 'UilCreateDashboard',
				path: pages.account.overview.path,
			},
			{
				title: 'Quote Requests',
				icon: 'UilMoneyInsert',
				path: pages.account.quoteRequests.path,
			},

			{
				title: 'Purchase Order Requests',
				icon: 'UilFileUploadAlt',
				path: pages.account.purchaseOrderRequests.path,
			},
			{
				title: 'Purchase Orders',
				icon: 'UilFileDownloadAlt',
				path: pages.account.purchaseOrders.path,
			},
			// {
			// 	title: 'Quotes',
			// 	icon: 'UilMoneyBill',
			// 	path: pages.account.quotes.path,
			// },
			{
				title: 'Statements',
				icon: 'UilNewspaper',
				path: pages.account.statements.path,
			},
			{
				title: 'Invoices',
				icon: 'UilMoneyWithdraw',
				path: pages.account.invoices.path,
			},
			{
				title: 'Credit Notes',
				icon: 'UilCreditCardSearch',
				path: pages.account.creditNotes.path,
			},
			{
				title: 'Delivery Notes',
				icon: 'UilNotes',
				path: pages.account.deliveryNotes.path,
			},
			{
				title: 'Help Requests',
				icon: 'UilQuestionCircle',
				path: pages.account.helpRequests.path,
			},
		],
	},
	{
		title: 'My Farm',
		icon: 'UilUser',
		open: false,
		path: '',
		casl: { action: 'view', access: Access[Access.MyFarm] },
		children: [
			{
				title: 'Spray Calculations',
				icon: 'UilRaindrops',
				path: pages.myFarm.sprayCalcs.path,
			},
			{
				title: 'Recommendations',
				icon: 'UilChatBubbleUser',
				path: pages.myFarm.recommendations.path,
			},
			{
				title: 'Field Assessments',
				icon: 'UilFileAlt',
				path: pages.myFarm.fieldAssessments.path,
			},
			{
				title: 'Technical Data',
				icon: 'UilFileGraph',
				path: pages.myFarm.technicalData.path,
			},
		],
	},
	{
		title: 'My Products',
		icon: 'UilBox',
		open: false,
		path: '',
		casl: { action: 'view', access: Access[Access.Products] },
		children: [
			{
				title: 'Crop Protection',
				icon: 'UilShield',
				path: pages.products.cropProtection.path,
			},
			{
				title: 'Plant Nutrition',
				icon: 'UilFlower',
				path: pages.products.plantNutrition.path,
			},
			{
				title: 'All Products',
				icon: 'UilPagelines',
				path: pages.products.allProducts.path,
			},
		],
	},
	{
		title: 'Team',
		icon: 'UilUsersAlt',
		path: pages.myTeam.path,
		casl: { action: 'read', access: 'users' },
	},
];
