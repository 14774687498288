import { ReactNode } from 'react';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { Font } from '@react-pdf/renderer';

Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
			fontStyle: 'normal',
			fontWeight: 'light',
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
			fontStyle: 'normal',
			fontWeight: 'medium',
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
			fontStyle: 'normal',
			fontWeight: 'bold',
		},
	],
});

Font.register({
	family: 'Noto Sans',
	src: 'https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d41P6zHtY.ttf',
	fontStyle: 'normal',
	fontWeight: 'normal',
});

export const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		padding: '2vw',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
	},
	w60: {
		width: '60%',
	},
	w70: {
		width: '70%',
	},
	w30: {
		width: '30%',
	},
	w40: {
		width: '40%',
	},
	w50: {
		width: '50%',
	},
	w20: {
		width: '20%',
	},
	w80: {
		width: '80%',
	},
	w90: {
		width: '90%',
	},
	w100: {
		width: '100%',
	},
	boldText: {
		fontWeight: 'bold',
	},
	tableCell: {
		padding: '8pt',
		margin: 0,
		borderRight: '1pt',
		borderBottom: '1pt',
	},
	font12: {
		fontSize: '12pt',
	},
	font11: {
		fontSize: '12pt',
	},
	font10: {
		fontSize: '10pt',
	},
	font9: {
		fontSize: '9pt',
	},
	font8: {
		fontSize: '8pt',
	},
	font7: {
		fontSize: '7pt',
	},
	tableHeader: {
		color: '#FFFFFF',
		fontSize: '12pt',
	},
	footerParagraph: {
		fontSize: '10pt',
		color: '#727272',
		textAlign: 'left',
		paddingHorizontal: '5pt',
		paddingVertical: '1px',
	},
	signatureSubText: {
		fontSize: '8pt',
		textAlign: 'left',
		fontWeight: 'bold',
	},
	signatureAltText: {
		fontSize: '10pt',
		color: '#727272',
		textAlign: 'center',
		padding: '20pt',
	},
	section: {
		width: '100%',
		marginTop: '2%',
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	headerCell: {
		borderTop: '1pt',
	},
	tickBox: {
		border: '1pt',
		borderRadius: '5pt',
		backgroundColor: '#F4F4F4',
	},

	// FONTS
	h4Bold: {
		fontFamily: 'Inter',
		fontWeight: 'bold',
		fontSize: '16pt',
	},
	h5Bold: {
		fontFamily: 'Inter',
		fontWeight: 'bold',
		fontSize: '12pt',
	},
	h6Bold: {
		fontFamily: 'Inter',
		fontWeight: 'bold',
		fontSize: '10pt',
	},
	h6Light: {
		fontFamily: 'Inter',
		fontWeight: 'light',
		fontSize: '10pt',
	},
	body: {
		fontFamily: 'Noto Sans',
		fontWeight: 'normal',
		fontSize: '8pt',
		lineHeight: '2pt',
		letterSpacing: '0.2pt',
	},
	footerHeading: {
		fontFamily: 'Inter',
		fontWeight: 'medium',
		fontSize: '8pt',
		color: '#727272',
		textAlign: 'center',
	},
	footerContent: {
		fontFamily: 'Noto Sans',
		fontWeight: 'normal',
		fontSize: '6pt',
		color: '#727272',
		textAlign: 'left',
	},
});

export const PdfHeader = ({ children }: { children: ReactNode }) => {
	return <View style={styles.row}>{children}</View>;
};

export const PdfContent = ({ children }: { children: ReactNode }) => {
	return (
		<View style={{ display: 'flex', justifyContent: 'space-between' }}>
			{children}
		</View>
	);
};

export const PdfRow = ({
	children,
	style,
}: {
	children: ReactNode;
	style?: Style[];
}) => {
	return <View style={[styles.row, ...(style ?? [])]}>{children}</View>;
};

export const PdfStack = ({
	children,
	style,
}: {
	children: ReactNode;
	style?: Style[];
}) => {
	return <View style={[styles.column, ...(style ?? [])]}>{children}</View>;
};

export const InfoHeader = ({
	text,
	header,
	style,
}: {
	text: string;
	header: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<Text style={[styles.boldText, styles.font10, styles.w30]}>
				{header}:
			</Text>
			<Text style={[styles.font10, styles.w70]}>{text}</Text>
		</PdfRow>
	);
};

export const Tickbox = ({ style }: { style?: Style[] }) => {
	return <View style={[styles.tickBox, ...(style ?? [])]} />;
};

export const ImageTickbox = ({
	style,
	dimensions,
	src,
	source,
}: {
	style?: Style[];
	dimensions: string;
	src: string;
	source: string;
}) => {
	return (
		<PdfRow style={[{ alignItems: 'center', gap: '5pt' }, ...(style ?? [])]}>
			<Image
				style={{ width: dimensions, height: dimensions }}
				src={src}
				source={source}
			/>
			<Tickbox style={[{ width: '20pt', height: '20pt' }]} />
		</PdfRow>
	);
};
