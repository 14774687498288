import { Suspense, lazy, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import pages from './routes';
import { SuspenseLoader } from 'features';
import BaseLayout from 'src/components/templates/BaseLayout';
import { SprayCalculations } from 'src/components/Pages/MyFarm/SprayCalculations';
import { SprayCalculationsDetail } from 'src/components/Pages/MyFarm/SprayCalculationsDetail';
import AuthGuard from 'src/Gaurds/authGaurd/AuthGaurd';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'src/contexts/CanContext';
import { Access } from 'src/store/user/userSlice.contracts';

const Loader =
	(Component: FC, access?: (ability: any) => boolean) => (props: any) => {
		const ability = useAbility(AbilityContext);
		let hasAccess = !access ? true : false;

		if (access !== undefined) {
			hasAccess = access(ability);
		}

		return hasAccess ? (
			<Suspense fallback={<SuspenseLoader />}>
				<Component {...props} />
			</Suspense>
		) : (
			<UnauthorizedAccess />
		);
	};

// My Account
const Overview = Loader(
	lazy(() => import('src/components/Pages/MyAccount/Overview/Overview')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const CreditNotes = Loader(
	lazy(() => import('src/components/Pages/MyAccount/CreditNotes')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const DeliveryNotes = Loader(
	lazy(() => import('src/components/Pages/MyAccount/DeliveryNotes')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const Invoices = Loader(
	lazy(() => import('src/components/Pages/MyAccount/Invoices')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const PurchaseOrders = Loader(
	lazy(() => import('src/components/Pages/MyAccount/PurchaseOrders')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const Quotes = Loader(
	lazy(() => import('src/components/Pages/MyAccount/Quotes')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const Statements = Loader(
	lazy(() => import('src/components/Pages/MyAccount/Statements')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const PurchaseOrderRequests = Loader(
	lazy(() => import('src/components/Pages/MyAccount/PurchaseOrderRequests')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const QuoteRequests = Loader(
	lazy(() => import('src/components/Pages/MyAccount/QuoteRequests')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const QuoteRequestDetail = Loader(
	lazy(() => import('src/components/Pages/MyAccount/QuoteRequestDetail')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const PurchaseOrderRequestDetail = Loader(
	lazy(
		() => import('src/components/Pages/MyAccount/PurchaseOrderRequestDetail')
	),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);
const HelpRequests = Loader(
	lazy(() => import('src/components/Pages/MyAccount/HelpRequests')),
	(ability: any) => ability.can('view', Access[Access.MyAccount])
);

// My Farm
const Recommendations = Loader(
	lazy(() => import('src/components/Pages/MyFarm/Recommendations')),
	(ability: any) => ability.can('view', Access[Access.MyFarm])
);
const TechnicalData = Loader(
	lazy(() => import('src/components/Pages/MyFarm/TechnicalData')),
	(ability: any) => ability.can('view', Access[Access.MyFarm])
);
const FieldAssessments = Loader(
	lazy(() => import('src/components/Pages/MyFarm/FieldAssessments')),
	(ability: any) => ability.can('view', Access[Access.MyFarm])
);

// Products
const AllProducts = Loader(
	lazy(() => import('src/components/Pages/Products/AllProducts')),
	(ability: any) => ability.can('view', Access[Access.Products])
);
const PlantNutrition = Loader(
	lazy(() => import('src/components/Pages/Products/PlantNutrition')),
	(ability: any) => ability.can('view', Access[Access.Products])
);
const CropProtection = Loader(
	lazy(() => import('src/components/Pages/Products/CropProtection')),
	(ability: any) => ability.can('view', Access[Access.Products])
);
const Cart = Loader(
	lazy(() => import('src/components/Pages/Cart/Cart')),
	(ability: any) => ability.can('view', Access[Access.Products])
);

const PdfViewer = Loader(
	lazy(() => import('src/components/Pages/PdfViewer/PdfViewer'))
);

// Users
const MyTeam = Loader(
	lazy(() => import('src/components/Pages/MyTeam')),
	(ability: any) => ability.can('read', 'users')
);

// Status
const SystemStatus = Loader(
	lazy(() => import('src/components/Pages/Status/System'))
);
const Status404 = Loader(
	lazy(() => import('src/components/Pages/Status/Status404'))
);
const Status500 = Loader(
	lazy(() => import('src/components/Pages/Status/Status500'))
);
const StatusComingSoon = Loader(
	lazy(() => import('src/components/Pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
	lazy(() => import('src/components/Pages/Status/Maintenance'))
);
const UnauthorizedAccess = Loader(
	lazy(() => import('src/components/Pages/Status/UnauthorizedAccess'))
);

const routes: RouteObject[] = [
	{
		/**
		 * Basic container element without a Sidebar, top Navbar, or authentication.
		 */
		path: '',
		element: '',
		children: [
			// BASE
			{
				path: '/',
				element: <Navigate to={pages.account.overview.path} replace />,
			},
			// STATUS
			{
				path: pages.status.root,
				children: [
					{
						path: '',
						element: <Navigate to="404" replace />,
					},
					{
						path: pages.status.systemStatus.path,
						element: (
							<AuthGuard>
								<SystemStatus />
							</AuthGuard>
						),
					},
					{
						path: pages.status.status404.path,
						element: <Status404 />,
					},
					{
						path: pages.status.status500.path,
						element: <Status500 />,
					},
					{
						path: pages.status.statusMaintenance.path,
						element: <StatusMaintenance />,
					},
					{
						path: pages.status.statusComingSoon.path,
						element: <StatusComingSoon />,
					},
				],
			},
			// NOT FOUND
			{
				path: '*',
				element: <Status404 />,
			},
		],
	},
	{
		/**
		 * This is the root path API_URL
		 */
		path: '',
		element: (
			<>
				<AuthGuard>
					<BaseLayout />
				</AuthGuard>
			</>
		),
		children: [
			{
				path: '/',
				element: <Navigate to={pages.account.overview.path} replace />,
			},
			{
				path: pages.cart.path,
				element: <Cart />,
			},
			{
				path: pages.myTeam.path,
				element: <MyTeam />,
			},
			{
				path: pages.myFarm.root,
				children: [
					{
						path: `/${pages.myFarm.root}`,
						element: <Navigate to={pages.myFarm.overview.path} replace />,
					},
					{
						path: pages.myFarm.overview.path,
						element: <SprayCalculations />,
					},
					{
						path: pages.myFarm.sprayCalcs.path,
						element: <SprayCalculations />,
					},
					{
						path: pages.myFarm.sprayCalcDetails.path,
						element: <SprayCalculationsDetail />,
					},
					{
						path: pages.myFarm.recommendations.path,
						element: <Recommendations />,
					},
					{
						path: pages.myFarm.technicalData.path,
						element: <TechnicalData />,
					},
					{
						path: pages.myFarm.fieldAssessments.path,
						element: <FieldAssessments />,
					},
				],
			},
			{
				path: pages.products.root,
				children: [
					{
						path: pages.products.cropProtection.path,
						element: <CropProtection />,
					},
					{
						path: pages.products.plantNutrition.path,
						element: <PlantNutrition />,
					},
					{
						path: pages.products.allProducts.path,
						element: <AllProducts />,
					},
				],
			},
			{
				path: pages.account.root,
				children: [
					{
						path: pages.account.overview.path,
						element: <Overview />,
					},
					{
						path: pages.account.quoteRequests.path,
						children: [
							{
								path: pages.account.quoteRequests.path + '/',
								element: <QuoteRequests />,
							},
							{
								path: pages.account.quoteRequests.path + '/:quoteRequestId',
								element: <QuoteRequestDetail />,
							},
						],
					},
					{
						path: pages.account.purchaseOrderRequests.path,
						children: [
							{
								path: pages.account.purchaseOrderRequests.path + '/',
								element: <PurchaseOrderRequests />,
							},
							{
								path:
									pages.account.purchaseOrderRequests.path +
									'/:purchaseOrderRequestId',
								element: <PurchaseOrderRequestDetail />,
							},
						],
					},
					{
						path: pages.account.quotes.path,
						element: <Quotes />,
					},
					{
						path: pages.account.statements.path,
						element: <Statements />,
					},
					{
						path: pages.account.deliveryNotes.path,
						element: <DeliveryNotes />,
					},
					{
						path: pages.account.invoices.path,
						element: <Invoices />,
					},
					{
						path: pages.account.creditNotes.path,
						element: <CreditNotes />,
					},
					{
						path: pages.account.purchaseOrders.path,
						children: [
							{
								path: pages.account.purchaseOrders.path + '/',
								element: <PurchaseOrders />,
							},
							{
								path: pages.account.purchaseOrders.path + '/invoices',
								element: <Invoices />,
							},
							{
								path: pages.account.purchaseOrders.path + '/delivery-notes',
								element: <DeliveryNotes />,
							},
						],
					},
					{
						path: pages.account.helpRequests.path,
						element: <HelpRequests />,
					},
				],
			},
			{
				path: pages.document.path,
				element: <PdfViewer />,
			},
		],
	},
];

export default routes;
