import { View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ReactNode } from 'react';
import { PdfRow, PdfStack, styles } from './PdfDocument';

interface PdfTableFieldProps {
	field: string;
	name: string;
	width: string | number;
	customContent?: boolean;
}

export const PdfTable = ({
	fields,
	data,
}: {
	fields: PdfTableFieldProps[];
	data: { [key: string]: string | number | ReactNode }[];
}) => {
	return (
		<Table>
			<PdfTableHead>
				{fields &&
					fields.map((f, columnIndex) => (
						<PdfTableHeadCell
							key={`header-${f.field}`}
							width={f.width}
							index={columnIndex}
						>
							<Text style={styles.tableHeader} wrap={false}>
								{f.name}
							</Text>
						</PdfTableHeadCell>
					))}
			</PdfTableHead>
			{data &&
				data.map((d, rowIndex) => (
					<PdfTableRow key={`row-${rowIndex}`}>
						{fields.map((f, columnIndex) => (
							<PdfTableCell
								key={`cell-${f.field}-${columnIndex}`}
								width={f.width}
								index={columnIndex}
							>
								{f.customContent ?? false ? (
									d[f.field]
								) : (
									<PdfTableText>{d[f.field]}</PdfTableText>
								)}
							</PdfTableCell>
						))}
					</PdfTableRow>
				))}
		</Table>
	);
};

export const Table = ({
	children,
	style,
}: {
	children: ReactNode;
	style?: Style[];
}) => <View style={style ?? []}>{children}</View>;

export const PdfTableHead = ({
	children,
	style,
}: {
	children: ReactNode;
	style?: Style[];
}) => <PdfRow style={style}>{children}</PdfRow>;

export const PdfTableHeadCell = ({
	children,
	width,
	index,
	style,
}: {
	children?: ReactNode;
	width: string | number;
	index: number;
	style?: Style[];
}) => (
	<View
		style={[
			styles.tableCell,
			styles.headerCell,
			{
				width,
				backgroundColor: '#509E2F',
				borderLeft: index === 0 ? '1pt' : '0pt',
			},
			...(style ?? []),
		]}
	>
		{children}
	</View>
);

export const PdfTableRow = ({ children }: { children: ReactNode }) => (
	<PdfRow>{children}</PdfRow>
);

export const PdfTableCell = ({
	children,
	width,
	index,
	style,
}: {
	children?: ReactNode;
	width: string | number;
	index: number;
	style?: Style[];
}) => (
	<View
		style={[
			styles.tableCell,
			{
				width,
				borderLeft: index === 0 ? '1pt' : '0pt',
			},
			...(style ?? []),
		]}
	>
		{children}
	</View>
);

export const PdfEmptyTableCell = ({
	width,
	index,
	style,
}: {
	width: string | number;
	index: number;
	style?: Style[];
}) => (
	<View
		style={[
			styles.tableCell,
			{
				width,
				borderLeft: index === 0 ? '1pt' : '0pt',
			},
			...(style ?? []),
		]}
	></View>
);

export const PdfTableInfoCell = ({
	value,
	width,
	index,
	style,
	header,
}: {
	value?: ReactNode;
	width: string | number;
	index: number;
	style?: Style[];
	header: string | ReactNode;
}) => (
	<View style={[{ height: '70pt' }]}>
		<PdfRow style={[{ height: '100%' }]}>
			<View
				style={[
					styles.tableCell,
					{
						width,
						borderLeft: index === 0 ? '1pt' : '0pt',
					},
					...(style ?? []),
				]}
			>
				{typeof header === 'string' ? (
					<Text style={[styles.font10]}>{header}</Text>
				) : (
					header
				)}
			</View>
			<View
				style={[
					styles.tableCell,
					{
						width,
					},
					...(style ?? []),
				]}
			>
				{value && value}
			</View>
		</PdfRow>
	</View>
);

export const PdfTableText = ({
	children,
	style,
}: {
	children: ReactNode;
	style?: Style[];
}) => {
	return <Text style={[styles.font10, ...(style ?? [])]}>{children}</Text>;
};

export const TableSection = ({
	children,
	containsStack,
	style,
}: {
	children: ReactNode;
	containsStack: boolean;
	style?: Style[];
}) => {
	return containsStack ? (
		<PdfStack style={[...(style ?? [])]}>{children}</PdfStack>
	) : (
		<>{children}</>
	);
};

export const TableRowSection = ({
	children,
	containsRow,
	style,
}: {
	children: ReactNode;
	containsRow: boolean;
	style?: Style[];
}) => {
	return containsRow ? (
		<PdfRow>{children}</PdfRow>
	) : (
		<PdfRow style={[...(style ?? [])]}>{children}</PdfRow>
	);
};
