import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { Farm } from './types/farm.types';
import { QueryParams } from './types/queryParams.types';
import { SprayCalculation } from './types/sprayCalculation.types';

const myFarmApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getSprayCalculations: builder.query<
			ApiResponse<SprayCalculation[]>,
			QueryParams
		>({
			query: (args) => {
				return {
					url: 'SprayCalculation',
					params: args,
				};
			},
			providesTags: ['SprayCalculations'],
		}),

		getSingleSprayCalculation: builder.query<
			ApiResponse<SprayCalculation>,
			{ id: string }
		>({
			query: (params) => {
				return {
					url: `SprayCalculation/${params.id}`,
				};
			},
			providesTags: (result) =>
				result
					? [
							{ type: 'SprayCalculation', id: result.data?.id },
							'SprayCalculation',
					  ]
					: ['SprayCalculation'],
		}),

		createSprayCalculation: builder.mutation<
			ApiResponse<SprayCalculation>,
			Partial<SprayCalculation>
		>({
			query(body) {
				return {
					url: 'SprayCalculation',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['SprayCalculations'],
		}),

		updateSprayCalculation: builder.mutation<
			ApiResponse<SprayCalculation>,
			Partial<SprayCalculation>
		>({
			query(body) {
				return {
					url: `SprayCalculation/${body.id}`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'SprayCalculation', id: result?.data?.id },
				'SprayCalculations',
			],
		}),

		deleteSprayCalculation: builder.mutation<
			SprayCalculation,
			Partial<SprayCalculation>
		>({
			query(body) {
				return {
					url: `SprayCalculation/${body.id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['SprayCalculations'],
		}),

		getUserFarms: builder.query<ApiResponse<Farm[]>, {}>({
			query: (id) => {
				return {
					url: 'SprayCalculation/UserFarms',
				};
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetSprayCalculationsQuery,
	useCreateSprayCalculationMutation,
	useDeleteSprayCalculationMutation,
	useGetSingleSprayCalculationQuery,
	useUpdateSprayCalculationMutation,
	useGetUserFarmsQuery,
} = myFarmApi;
