import React, { useContext, useState } from 'react';
import { AppBar, Badge, Box, useTheme } from '@mui/material';
import { UserContainer } from './UserContainer';
import {
	HeaderWrapper,
	StyledDivider,
	StyledIconButton,
} from './Header.styles';
import { Breadcrumbs, Button, Icon } from 'features';
import { QuestionModal } from 'src/components/organisms/QuestionModal/QuestionModal';
import { NotificationModal } from 'src/components/organisms/NotificationModal/Notification';
import CartModal from 'src/components/organisms/CartModal/CartModal';
import { useRemoteConfigFlags } from 'src/hooks/useRemoteConfigFlags';
import { useAppSelector } from 'src/hooks/hooks';
import { selectCart } from 'src/store/cart/cartSlice';
import { SidebarContext } from 'src/contexts/SidebarContext';

const messagesData = [
	{
		id: 1,
		isRead: false,
		message: 'This is a notification message',
		createdAt: 'Yesterday at 11:42 PM',
	},
	{
		id: 2,
		isRead: false,
		message: 'This is a notification message',
		createdAt: 'Yesterday at 11:42 PM',
	},
	{
		id: 3,
		isRead: false,
		message: 'This is a notification message',
		createdAt: 'Yesterday at 11:42 PM',
	},
];

const Header = () => {
	const cart = useAppSelector(selectCart);
	const [questionModal, setQuestionModal] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [cartModalState, setCartModalState] = useState(false);
	const featureFlags = useRemoteConfigFlags();
	const handleQuestionModal = () => {
		setQuestionModal(!questionModal);
	};

	const sidebarContext = useContext(SidebarContext);
	const theme = useTheme();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleToggleSidebar = () => {
		sidebarContext.toggleSidebar();
	}

	const cartQuantity = cart?.products.length
		? cart.products.map((product) => product.quantity).reduce((a, b) => a + b)
		: 0;

	const drawerWidth = sidebarContext.open
		? theme.sidebar.width
		: theme.spacing(8);

	return (
		<>
			<AppBar
				position="fixed"
				color="default"
				elevation={0}
				sx={{
					width: { xs: '100%', bg: `calc(100% - ${drawerWidth})` },
					ml: `${drawerWidth}`,
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
					}),
				}}
			>
				<HeaderWrapper
					sx={{
						width: '100%',
						justifyContent: 'space-between',
						paddingLeft: { xs: '10px', md: `${drawerWidth}` },
						marginLeft: { xs: 0, md: '10px' }
					}}
				>
					<Breadcrumbs
						separator={<Icon icon="UilAngleRight" />}
						sx={{
							[theme.breakpoints.down('md')]: {
								display: 'none',
							},
						}}
					/>
					<Icon icon="UilBars" sx={{ display: { xs: 'block', sm: 'block', md: 'none'} }} onClick={handleToggleSidebar}/>
					<Box display={'flex'} alignItems={'center'} gap={'10px'}>
						<Button
							variant="contained"
							color="primary"
							// endIcon={'UilQuestionCircle'}
							size="small"
							onClick={handleQuestionModal}
						>
							Ask a question
						</Button>
						<QuestionModal
							modalState={questionModal}
							closeModal={handleQuestionModal}
							openModal={handleQuestionModal}
						/>
						{featureFlags && !featureFlags['headerNotificationDisabled'] && (
							<>
								<StyledIconButton
									aria-label="bell"
									onClick={handleClick}
									icon="UilBell"
								/>
								<NotificationModal
									anchorEl={anchorEl}
									handleClose={handleClose}
									messages={messagesData}
								/>
							</>
						)}
						<Badge
							badgeContent={cartQuantity}
							color="primary"
							sx={{
								'& .MuiBadge-badge': {
									right: 6,
									top: 10,
									border: '1px solid',
									padding: '0 0px',
								},
							}}
						>
							<StyledIconButton
								aria-label="cart"
								icon="UilShoppingCart"
								onClick={() => setCartModalState(true)}
							/>
						</Badge>
						<StyledDivider orientation="vertical" />
						<UserContainer
							menuItems={[
								{
									key: '1',
									children: 'Edit Profile',
									icon: 'UilPen',
								},

								{
									key: '3',
									children: 'Logout',
									icon: 'UilSignout',
								},
							]}
						/>
					</Box>
				</HeaderWrapper>
			</AppBar>
			<CartModal
				cartOpen={cartModalState}
				closeCartEvent={() => setCartModalState(false)}
			/>
		</>
	);
};

export default Header;
