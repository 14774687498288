import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, CustomTextInput, Icon, LoadingButton, ModalWrapper } from 'features';
import {
	SprayCalculationTypes,
	getSprayTypeString,
} from 'src/helpers/typeHelpers';
import pages from 'src/router/routes';
import * as Yup from 'yup';
import { Block, Farm } from 'src/services/rtkQuery/types/farm.types';
import { useGetUserFarmsQuery } from 'src/services/rtkQuery/myFarmApi';
import { ChangeEvent, useState } from 'react';

interface CreateSprayCalcModalProps {
	modalOpenState: boolean;
	closeModal: () => void;
	openModal: () => void;
}

export interface SprayCalculationFarm {
	sprayType: SprayCalculationTypes;
	farm?: Farm;
	farmId: string;
	farmBlock?: Block;
	farmBlockId: string;
}

const CreateSprayCalcModal = ({
	modalOpenState,
	closeModal,
}: CreateSprayCalcModalProps) => {
	const theme = useTheme();
	const [farmBlocks, setFarmBlocks] = useState<Block[]>([]);

	const { data } = useGetUserFarmsQuery({});

	const initValues: SprayCalculationFarm = {
		sprayType: SprayCalculationTypes.BOOMARABLE,
		farmId: '',
		farmBlockId: '',
	};

	const navigate = useNavigate();

	return (
		<Dialog fullWidth maxWidth={'md'} open={modalOpenState}>
			<Formik
				initialValues={initValues}
				onSubmit={(values: SprayCalculationFarm) => {
					navigate(pages.myFarm.sprayCalcDetails.path, {
						state: {
							sprayCalculationType: values,
						},
					});
				}}
				enableReinitialize
			>
				{({ values, setFieldValue, isSubmitting }) => {
					return (<Box sx={{ padding: 2 }}>
						<Form>

							<DialogContent>
								<Box paddingBottom={'15px'}>
									<Typography variant='h6Bold'>Add New Spray Calculation</Typography>
								</Box>

								<Divider />

								<Box marginTop={'15px'}>
									<Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<FormLabel
													sx={{
														fontWeight: 'bold',
														color: theme.colors.alpha.black[100],
													}}
												>
													Farm
												</FormLabel>
												<Field
													as={Select}
													name="farmId"
													value={values.farmId ?? ''}
													fullwidth
													onChange={(event: SelectChangeEvent<string>) => {
														if (event.target.value) {
															const selectedFarm = data?.data?.find(
																(x) => x.id === event.target.value
															);
															setFieldValue('farmId', selectedFarm?.id);
															setFieldValue('farm', selectedFarm);
															setFarmBlocks(selectedFarm?.farmBlocks ?? []);
														}
													}}
												>
													{data?.data?.map((farm) => {
														return (
															<MenuItem
																value={farm.id}
																key={`farm-menuitem-${farm.id}`}
															>
																{farm.name}
															</MenuItem>
														);
													})}
												</Field>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<FormLabel
													sx={{
														fontWeight: 'bold',
														color: theme.colors.alpha.black[100],
													}}
												>
													Block
												</FormLabel>
												<Field
													as={Select}
													name="farmBlockId"
													value={values.farmBlockId ?? ''}
													fullwidth
													disabled={!values.farmId}
													onChange={(event: SelectChangeEvent<string>) => {
														if (event.target.value) {
															const selectedFarmBlock =
																values.farm?.farmBlocks.find(
																	(x) => x.id === event.target.value
																);
															setFieldValue(
																'farmBlockId',
																selectedFarmBlock?.id
															);
															setFieldValue('farmBlock', selectedFarmBlock);
														}
													}}
												>
													{values.farmId && farmBlocks ? (
														farmBlocks.map((block) => {
															return (
																<MenuItem
																	value={block.id}
																	key={`block-menuitem-${block.id}`}
																>
																	{block.name}
																</MenuItem>
															);
														})
													) : (
														<MenuItem>
															No blocks available for selection
														</MenuItem>
													)}
												</Field>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<Field
												as={CustomTextInput}
												value={
													values.farmBlock?.farmBlockCropInfo.length
														? values.farmBlock?.farmBlockCropInfo[0].crop
															?.name
														: ''
												}
												label={'Crop'}
												fullwidth
												disabled={true}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<Field
												as={CustomTextInput}
												value={
													values.farmBlock?.farmBlockCropInfo.length
														? values.farmBlock?.farmBlockCropInfo[0].cultivar
															?.name
														: ''
												}
												label={'Cultivar'}
												fullwidth
												disabled={true}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<FormLabel
													sx={{
														fontWeight: 'bold',
														color: theme.colors.alpha.black[100],
													}}
												>
													Size of Block
												</FormLabel>
												<Field
													as={CustomTextInput}
													value={
														values.farmBlock
															? values.farmBlock?.blockSize
															: ''
													}
													fullwidth
													disabled={true}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<FormLabel
													sx={{
														fontWeight: 'bold',
														color: theme.colors.alpha.black[100],
													}}
												>
													Select spray type
												</FormLabel>
												<Field
													as={Select}
													name="sprayType"
													value={values.sprayType ?? ''}
													fullwidth
												>
													<MenuItem
														value={SprayCalculationTypes.BOOMARABLE}
														key={`spray-type-menuitem-${SprayCalculationTypes.BOOMARABLE}`}
													>
														{getSprayTypeString(
															SprayCalculationTypes.BOOMARABLE
														)}
													</MenuItem>
													<MenuItem
														value={SprayCalculationTypes.ORCHARD}
														key={`spray-type-menuitem-${SprayCalculationTypes.ORCHARD}`}
													>
														{getSprayTypeString(
															SprayCalculationTypes.ORCHARD
														)}
													</MenuItem>
													<MenuItem
														value={SprayCalculationTypes.WEED}
														key={`spray-type-menuitem-${SprayCalculationTypes.WEED}`}
													>
														{getSprayTypeString(SprayCalculationTypes.WEED)}
													</MenuItem>
												</Field>
											</FormControl>
										</Grid>
									</Grid>
								</Box>
							</DialogContent>
							<DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<LoadingButton variant='contained' type='submit' loading={isSubmitting} disabled={isSubmitting} sx={{ width: '183px' }}>
									Save
								</LoadingButton>
								<Button variant='outlined' color='secondary' sx={{ width: '183px' }} onClick={closeModal}>
									Close
								</Button>
							</DialogActions>
						</Form>
					</Box>
					);
				}}
			</Formik>
		</Dialog>
	);
};

export default CreateSprayCalcModal;
