import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Dialog,
  MobileStepper,
  Stack,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '../../atoms/Button/Button';
import { IconButton } from '../../atoms/IconButton/IconButton';
import { downloadFileFromExternalUrl } from 'features';

const CarouselWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  borderRadius: '30px',
  backgroundColor: theme.colors.alpha.white[100]
}));

interface ImageCarouselProps {
  urls: string[];
  open: boolean;
  closeModal: () => void;
  initialActiveStep?: number | undefined;
}

export const ImageCarousel = ({ urls, open, closeModal, initialActiveStep }: ImageCarouselProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = urls.length;

  useEffect(() => {
    if (initialActiveStep !== undefined) {
      setActiveStep(initialActiveStep)
    }
  }, [initialActiveStep])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDownload = async () => {
    await downloadFileFromExternalUrl(urls[activeStep], 'image.png');
  };

  const handleCloseCarousel = () => {
    closeModal();
    setActiveStep(0);
  }

  return ( 
    <Dialog open={open} fullWidth={true} maxWidth={'md'}>
      <CarouselWrapper>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          marginRight="20px"
        >
          <IconButton
            icon="UilDownloadAlt"
            aria-label="download"
            onClick={handleDownload}
          />
          <IconButton
            icon="UilMultiply"
            aria-label="close"
            onClick={handleCloseCarousel}
          />
        </Stack>
        <Box 
          display='flex'
          justifyContent='center'
          height='100%'
        >
          <img src={urls[activeStep]} width={'75%'} />
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </CarouselWrapper>
    </Dialog>
  );
};
