import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import {
	InviteUserRequestBody,
	ToggleUserBody,
	UpdateUserAdminRights,
	UpdateUserBody,
	UsersQueryParams,
} from './types/queryParams.types';
import { User } from './types/user.types';

const userApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		updateCropPreferences: builder.mutation<
			ApiResponse<string[]>,
			{ cropPreferences: string[] }
		>({
			query(body) {
				return {
					url: 'UserPreferences/Crops',
					method: 'Patch',
					body,
					cache: 'no-cache',
				};
			},
			invalidatesTags: ['UserCrops'],
		}),

		getUserTeam: builder.query<ApiResponse<User[]>, UsersQueryParams>({
			query(args) {
				return {
					url: 'User/UserTeam',
					method: 'Get',
					params: args,
				};
			},
			providesTags: ['Users'],
		}),

		getUser: builder.query<ApiResponse<User>, string>({
			query(id) {
				return {
					url: `User/${id}`,
					method: 'Get',
				};
			},
			providesTags: (result) =>
				result
					? [{ type: 'User', id: result.data?.bcUniqueId }, 'Users']
					: ['User'],
		}),

		inviteUser: builder.mutation<ApiResponse<User>, InviteUserRequestBody>({
			query(body) {
				return {
					url: 'User/InviteUser',
					method: 'Post',
					body,
				};
			},
			invalidatesTags: ['User', 'Users'],
		}),

		toggleUser: builder.mutation<ApiResponse<User>, ToggleUserBody>({
			query(body) {
				return {
					url: 'User/Enabled',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'User', id: result?.data?.bcUniqueId },
				'Users',
			],
		}),

		updateUser: builder.mutation<ApiResponse<User>, UpdateUserBody>({
			query(body) {
				return {
					url: 'User',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'User', id: result?.data?.bcUniqueId },
				'Users',
			],
		}),

		updateUserAdminRights: builder.mutation<
			ApiResponse<User>,
			UpdateUserAdminRights
		>({
			query(body) {
				return {
					url: 'User/UserAdminRights',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'User', id: result?.data?.bcUniqueId },
				'Users',
			],
		}),

		checkUserByEmail: builder.query<
			ApiResponse<{ exist: boolean; user: User }>,
			{ email: string }
		>({
			query(args) {
				return {
					url: 'User/CheckUser',
					method: 'Get',
					params: args,
				};
			},
			providesTags: ['Users'],
		}),
	}),

	overrideExisting: false,
});

export const {
	useUpdateCropPreferencesMutation,
	useGetUserTeamQuery,
	useGetUserQuery,
	useInviteUserMutation,
	useToggleUserMutation,
	useLazyGetUserQuery,
	useUpdateUserMutation,
	useUpdateUserAdminRightsMutation,
	useCheckUserByEmailQuery,
} = userApi;
