import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { QueryParams } from './types/queryParams.types';
import { Question } from './types/question.types';

const helpRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    addQuestion: builder.mutation<ApiResponse<Question>, Partial<Question>>({
      query(params) {
        const data = new FormData();
        data.append('type', params.type as string);
        data.append('subject', params.subject as string);
        data.append('message', params.message as string);
        data.append('hasImages', params.hasImages?.toString() as string);
        params.files?.forEach((file) => {
          //@ts-ignore
          data.append('files', file as Blob, file.name);
        });
        
        return {
          headers: {
            'Login-Url': process.env.REACT_APP_INTELIGRO_SSO_URL,
          },
          url: 'Question',
          method: 'POST',
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ['Questions']
    }),

    getHelpRequests: builder.query<ApiResponse<Question[]>, QueryParams>({
      query(params) {
        return {
          url: 'Question',
          params
        };
      },
      providesTags: ['Questions']
    }),

    helpRequestResend: builder.mutation<ApiResponse<Question>, string>({
      query(id) {
        return {
          url: `Question/${id}/ResendQuestion`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['Questions']
    })
  }),
  overrideExisting: false
});

export const {
  useAddQuestionMutation,
  useGetHelpRequestsQuery,
  useHelpRequestResendMutation
} = helpRequestApi;
