import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import {
	PdfContent,
	PdfStack,
	PdfRow,
	styles,
	FieldAssessment,
	UserProfile,
} from 'features';

// Displays a row within a PdfStack with a title and value
// e.g. Crop Advisor: Wessel Combrinck
const InfoHeader = ({
	text,
	header,
	style,
}: {
	text: string;
	header: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<Text style={[styles.h6Bold, styles.w40]}>{header}:</Text>
			<Text style={[styles.h6Light, styles.w90]}>{text}</Text>
		</PdfRow>
	);
};

// Displays a row within a PdfStack with a single string
// And text aligned to the right
// To display the Inteligro details under their logo
const InfoWithoutHeader = ({
	text,
	style,
}: {
	text: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<View
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<Text style={[styles.font8]}>{text}</Text>
			</View>
		</PdfRow>
	);
};

export interface FieldAssessmentPdfProps {
	fieldAssessment?: FieldAssessment;
	user?: UserProfile;
	logoUrl: string;
}

export const FieldAssessmentPdf = ({
	fieldAssessment,
	user,
	logoUrl,
}: FieldAssessmentPdfProps) => {
	const { client, farm, generalObservation, observations, createdDate } =
		fieldAssessment ?? {
			client: {},
			farm: {},
			generalObservation: '',
			observations: [],
		};

	return (
		<Document title={fieldAssessment?.name} author="InteliGro">
			<Page
				style={{ flexDirection: 'row', padding: '6% 6% 2% 6%' }}
				orientation="portrait"
				wrap
				break
				size={'A4'}
				fixed
			>
				<PdfContent>
					<View style={[styles.row, { justifyContent: 'space-between' }]}>
						<PdfStack style={[{ width: '60%' }]}>
							<Text style={[styles.boldText, { fontSize: '16pt' }]}>
								FIELD ASSESSMENT REPORT
							</Text>
							<PdfRow style={[{ marginTop: '10pt' }]}>
								<PdfStack style={[styles.w100, { gap: '2pt' }]}>
									<InfoHeader header="Producer" text={client?.name ?? ''} />
									<InfoHeader header="Farm" text={farm?.name ?? ''} />
									<InfoHeader
										header="Report Date"
										text={fieldAssessment?.dateSubmitted ?? ''}
									/>
									<InfoHeader header="Crop Advisor" text={user?.name ?? ''} />
								</PdfStack>
							</PdfRow>
						</PdfStack>

						<PdfStack
							style={[{ width: '40%', gap: '2pt', alignItems: 'center' }]}
						>
							<Image
								src={logoUrl ?? 'src/assets/img/inteligro-logo.png'}
								source={'Powered by InteliGro'}
							/>
							<InfoWithoutHeader
								style={[{ marginTop: '5pt' }]}
								text={'www.inteligro.co.za | info@inteligro.co.za'}
							/>
							<InfoWithoutHeader
								text={'Wellington: 021 873 6177 | Viljoenskroon: 056 343 344'}
							/>
						</PdfStack>
					</View>

					<View
						style={[
							styles.column,
							{ justifyContent: 'flex-start', height: '100%', gap: '10pt' },
						]}
					>
						{generalObservation && (
							<View style={styles.w100}>
								<Text style={[styles.h5Bold, { marginTop: '10pt' }]}>
									General Summary
								</Text>
								<Text style={[styles.body, { marginTop: '10pt' }]}>
									{generalObservation}
								</Text>
							</View>
						)}
						{observations &&
							observations.map(
								(x) =>
									x.observationFarmBlocks &&
									x.observationFarmBlocks.map((y) => {
										const title = y.cultivar
											? `${
													y.farmBlock.name
											  }:  ${y.cultivar.name.toUpperCase()} ${y.cultivar.parentCrop.name.toUpperCase()}`
											: y.farmBlock.name;
										return (
											<View
												style={[
													styles.column,
													{ marginTop: generalObservation ? '0pt' : '10pt' },
												]}
												key={y.id}
											>
												<Text style={[styles.h5Bold, { marginBottom: '20pt' }]}>
													{title}
												</Text>
												<View
													style={[
														styles.row,
														{ gap: '5pt', marginBottom: '10pt' },
													]}
												>
													{x.images &&
														x.images.map((url, i) => {
															return (
																<Image
																	src={url}
																	key={i}
																	style={{
																		maxHeight: '200pt',
																		maxWidth: '200pt',
																	}}
																/>
															);
														})}
												</View>
												<Text style={styles.body}>{x.note}</Text>
											</View>
										);
									})
							)}
					</View>

					<View fixed>
						<PdfRow style={[styles.w100, { gap: '10pt', marginTop: '10pt' }]}>
							<PdfStack style={[styles.center, { width: '20%', gap: '10pt' }]}>
								{fieldAssessment?.signature ? (
									<Image
										src={fieldAssessment?.signature}
										source={'Signature'}
										style={{ maxWidth: '60%', maxHeight: '40pt' }}
									/>
								) : (
									<Text style={styles.footerHeading}>"Signature"</Text>
								)}
								{user?.role && (
									<Text style={styles.footerHeading}>{user.role}</Text>
								)}
							</PdfStack>
							<PdfStack style={[{ width: '80%', justifyContent: 'flex-end' }]}>
								<Text style={styles.footerContent} wrap={false}>
									This farm visit report is provided based on observations made
									on {createdDate}. While every effort has been made to ensure
									accuracy, it represents the professional opinion of the
									advisor or specialist to the best of their knowledge on the
									day of the visit.
								</Text>
								<Text
									style={[styles.footerContent, { marginTop: '5pt' }]}
									wrap={false}
								>
									Hierdie plaasbesoekverslag word verskaf gebaseer op
									waarnemings wat op {createdDate} gemaak is. Alhoewel elke
									poging aangewend is om akkuraatheid te verseker,
									verteenwoordig dit die professionele mening van die adviseur
									of spesialis tot die beste van hul kennis op die dag van die
									besoek.
								</Text>
							</PdfStack>
						</PdfRow>
					</View>
				</PdfContent>
			</Page>
		</Document>
	);
};
