import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import dayjs from 'dayjs';
import {
	PdfTable,
	PdfTableCell,
	PdfTableHead,
	PdfTableHeadCell,
	PdfTableText,
	Table,
	TableRowSection,
	TableSection,
} from 'features/src/organisms/PdfDocument/PdfTable';
import {
	PdfContent,
	PdfHeader,
	PdfStack,
	PdfRow,
	styles,
	RecommendationExtended,
} from 'features';
import { User } from 'firebase/auth';

// Displays a row within a PdfStack with a title and value
// e.g. Crop Advisor: Wessel Combrinck
const InfoHeader = ({
	text,
	header,
	style,
}: {
	text: string;
	header: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<Text style={[styles.boldText, styles.font10, styles.w30]}>
				{header}:
			</Text>
			<Text style={[styles.font10, styles.w70]}>{text}</Text>
		</PdfRow>
	);
};

// Displays a row within a PdfStack with a single string
// And text aligned to the right
// To display the Inteligro details under their logo
const InfoWithoutHeader = ({
	text,
	style,
}: {
	text: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<View
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<Text style={[styles.font8]}>{text}</Text>
			</View>
		</PdfRow>
	);
};

type FieldType = {
	name: string;
	width: string | number;
};

export interface RecommendationPdfProps {
	recommendation?: RecommendationExtended;
	user: User | null;
	signatureUrl?: string;
	apiUrl: string;
	logoUrl: string;
}

export const RecommendationPdf = (props: RecommendationPdfProps) => {
	const { recommendation, farmBlocks, products, productTotals, cropAdvisor } =
		props.recommendation ?? {
			recommendation: {},
			farmBlocks: [],
			products: [],
			productTotals: [],
			cropAdvisor: {},
		};

	const fields: Record<string, FieldType> = {
		farmArea: { name: 'Farm Area', width: '10vw' },
		product: { name: 'Product', width: '10vw' },
		toa: { name: 'TOU', width: '10vw' },
		regDosage: { name: 'Reg Dosage', width: '9vw' },
		sprayVolumeHa: { name: 'Spray Volume /ha', width: '9vw' },
		totalProductHa: { name: 'Total Product /ha', width: '9vw' },
		totalProduct: { name: 'Total Product', width: '9vw' },
		phi: { name: 'PHI', width: '10vw' },
		purpose: { name: 'Purpose', width: '10vw' },
		comments: { name: 'Comments', width: '20vw' },
	};

	let rowIndex = 0;

	return (
		<Document title={recommendation.name} author="InteliGro">
			<Page
				style={styles.page}
				orientation="landscape"
				wrap
				break
				size={'A4'}
				fixed
			>
				<PdfContent>
					<View>
						<PdfHeader>
							<PdfStack style={[{ width: '75%' }]}>
								<Text style={[styles.boldText, { fontSize: '16pt' }]}>
									RECOMMENDATION
								</Text>
								<Text style={[styles.boldText, { fontSize: '14pt' }]}>
									FOR APPLICATIONS OF AGRICULTURAL CHEMICALS ON CROPS
								</Text>
								<PdfRow style={[{ marginTop: '10pt' }]}>
									<PdfStack style={[styles.w50, { gap: '2pt' }]}>
										<InfoHeader
											header="Name"
											text={recommendation.name ?? ''}
										/>
										<InfoHeader
											header="Report Date"
											text={dayjs().format('YYYY-DD-MM').toString()}
										/>
										<InfoHeader
											header="Producer"
											text={recommendation?.clientName ?? ''}
										/>
										<InfoHeader
											header="Farm"
											text={recommendation.farm?.name ?? ''}
										/>
									</PdfStack>
									<PdfStack style={[styles.w50, { gap: '2pt' }]}>
										<InfoHeader
											header="Crop Advisor"
											text={cropAdvisor?.name ?? ''}
										/>
										<InfoHeader
											header="AVCASA"
											text={cropAdvisor?.cropLifeSaId ?? ''}
										/>
										<InfoHeader
											header="BASOS"
											text={cropAdvisor?.basosId ?? ''}
										/>
										<InfoHeader
											header="Cell"
											text={cropAdvisor?.cellphone ?? ''}
										/>
										<InfoHeader
											header="Email"
											text={cropAdvisor?.email ?? ''}
										/>
										<PdfRow>
											<Text
												style={[styles.boldText, styles.font10, styles.w30]}
											>
												Address:
											</Text>
											<PdfStack>
												{cropAdvisor?.depot?.address && (
													<Text style={[styles.font10, styles.w70]}>
														{cropAdvisor?.depot?.address}
													</Text>
												)}
												{cropAdvisor?.depot?.city && (
													<Text style={[styles.font10, styles.w70]}>
														{cropAdvisor?.depot?.city}
													</Text>
												)}
												{cropAdvisor?.depot?.province && (
													<Text style={[styles.font10, styles.w70]}>
														{cropAdvisor?.depot?.province}
													</Text>
												)}
												{cropAdvisor?.depot?.country && (
													<Text style={[styles.font10, styles.w70]}>
														{cropAdvisor?.depot?.country}
													</Text>
												)}
												{cropAdvisor?.depot?.postalCode && (
													<Text style={[styles.font10, styles.w70]}>
														{cropAdvisor?.depot?.postalCode}
													</Text>
												)}
											</PdfStack>
										</PdfRow>
									</PdfStack>
								</PdfRow>
							</PdfStack>

							<PdfStack
								style={[{ width: '25%', gap: '2pt', alignItems: 'flex-end' }]}
							>
								<Image src={props.logoUrl} source={'Powered by InteliGro'} />
								<InfoWithoutHeader
									style={[{ marginTop: '5pt' }]}
									text={'www.inteligro.co.za | info@inteligro.co.za'}
								/>
								<InfoWithoutHeader
									text={'Wellington: 021 873 6177 | Viljoenskroon: 056 343 344'}
								/>
							</PdfStack>
						</PdfHeader>

						{/* Products Table */}
						<View style={styles.section} wrap>
							<Table>
								<PdfTableHead>
									{Object.entries(fields).map(([key, value], columnIndex) => (
										<PdfTableHeadCell
											key={`header-${key}`}
											width={value.width}
											index={columnIndex}
										>
											<Text style={styles.tableHeader} wrap={false}>
												{value.name}
											</Text>
										</PdfTableHeadCell>
									))}
								</PdfTableHead>

								{farmBlocks &&
									farmBlocks.map((farmBlock, farmBlockIndex) => {
										return (
											<>
												{farmBlock.farmBlockCropInfo &&
													farmBlock.farmBlockCropInfo.map((cropInfo) => {
														const recommendationProducts =
															cropInfo.recommendationProducts ?? [];

														const evenRow = rowIndex % 2 === 0;
														rowIndex++;

														return (
															<PdfRow key={`row-${farmBlockIndex}`}>
																<PdfTableCell
																	style={[
																		styles.column,
																		styles.font10,
																		{
																			backgroundColor: evenRow
																				? '#808080'
																				: '#FFFFFF',
																			height: 'auto',
																			borderLeft: '1pt',
																		},
																	]}
																	width={fields['farmArea'].width}
																	index={farmBlockIndex}
																>
																	<PdfTableText
																		style={[
																			{
																				width: '100%',
																				color: evenRow ? '#FFFFFF' : '#000000',
																			},
																		]}
																	>
																		{farmBlock.name}
																	</PdfTableText>
																	<PdfTableText
																		style={[
																			{
																				color: evenRow ? '#FFFFFF' : '#000000',
																			},
																		]}
																	>
																		({farmBlock.blockSize}ha)
																	</PdfTableText>
																	<PdfTableText
																		style={[
																			{
																				color: evenRow ? '#FFFFFF' : '#000000',
																			},
																		]}
																	>
																		{cropInfo.crop.name ?? ''}
																	</PdfTableText>
																	<PdfTableText
																		style={[
																			{
																				color: evenRow ? '#FFFFFF' : '#000000',
																			},
																		]}
																	>
																		({cropInfo.cultivar.name ?? ''})
																	</PdfTableText>
																</PdfTableCell>
																<TableSection
																	containsStack={
																		recommendationProducts.length > 1
																	}
																>
																	{recommendationProducts &&
																		recommendationProducts.map((p, i) => (
																			<TableRowSection
																				key={`product-${i}`}
																				containsRow={
																					recommendationProducts.length > 1
																				}
																			>
																				<PdfTableCell
																					width={fields['product'].width}
																					index={1}
																				>
																					<PdfTableText>
																						{products.find(
																							(x) => x.no == p.product.productId
																						)?.description ?? ''}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['toa'].width}
																					index={2}
																				>
																					<PdfTableText>
																						{p.timesOfApplication &&
																						p.timesOfApplication.length
																							? p.timesOfApplication[0].name
																							: ''}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['regDosage'].width}
																					index={3}
																				>
																					<PdfTableText>
																						{`${p.product.registeredDosage} ${
																							p.product.unitOfMeasure.includes(
																								'unit/s'
																							)
																								? p.product.unitOfMeasure.replace(
																										's',
																										''
																								  )
																								: p.product.unitOfMeasure
																						}${
																							p.product.productApplicationType
																						}`}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['sprayVolumeHa'].width}
																					index={4}
																				>
																					<PdfTableText>
																						{p.product.sprayVolume}L
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['totalProductHa'].width}
																					index={5}
																				>
																					<PdfTableText>
																						{p.product.totalProduct}
																						{p.product.unitOfMeasure}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['totalProduct'].width}
																					index={6}
																				>
																					<PdfTableText>
																						{p.product.totalProduct *
																							(farmBlock.blockSize ??
																								0)}
																						{p.product.unitOfMeasure}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['phi'].width}
																					index={7}
																				>
																					<PdfTableText>
																						{p.product.preharvestInterval}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['purpose'].width}
																					index={8}
																				>
																					<PdfTableText>
																						{p.product.purpose}
																					</PdfTableText>
																				</PdfTableCell>
																				<PdfTableCell
																					width={fields['comments'].width}
																					index={9}
																				>
																					<PdfTableText>
																						{p.product.comments}
																					</PdfTableText>
																				</PdfTableCell>
																			</TableRowSection>
																		))}
																</TableSection>
															</PdfRow>
														);
													})}
											</>
										);
									})}
							</Table>
						</View>

						{/* Product Totals Table */}
						<View style={styles.section} wrap>
							<PdfTable
								fields={[
									{
										field: 'productName',
										name: 'Product Name',
										width: '40%',
									},
									{
										field: 'labels',
										name: 'Labels',
										width: '20%',
										customContent: true,
									},
									{
										field: 'total',
										name: 'Total Product',
										width: '40%',
									},
								]}
								data={productTotals.map((x) => {
									return {
										productName: x.productName,
										total: `${x.total}${x.unitOfMeasure}`,
										labels: (
											<View>
												{x.labels &&
													x.labels.map((l) => (
														<Link
															key={`label-${l.fileName}`}
															src={`${props.apiUrl}LibraryData/DownloadFile/${l.id}`}
															style={[
																styles.font10,
																{ color: '#000000', textDecoration: 'none' },
															]}
														>
															{l.fileName}
														</Link>
													))}
											</View>
										),
									};
								})}
							/>
						</View>
					</View>

					<View>
						<PdfRow
							style={[styles.section, { height: '50pt', alignItems: 'center' }]}
						>
							<View style={{ width: '15%' }}>
								<PdfStack
									style={[styles.center, { width: '100%', gap: '10pt' }]}
								>
									{!!props.signatureUrl ? (
										<Image
											style={{ width: '75%' }}
											src={props.signatureUrl}
											source={'Signature'}
										/>
									) : (
										<Text style={[styles.footerHeading]}>"Signature"</Text>
									)}
									{props.recommendation?.cropAdvisor?.name && (
										<Text style={styles.signatureSubText}>
											{props.recommendation?.cropAdvisor?.name}
										</Text>
									)}
									{props.recommendation?.cropAdvisor?.role && (
										<Text style={styles.signatureSubText}>
											{props.recommendation?.cropAdvisor?.role}
										</Text>
									)}
								</PdfStack>
							</View>
							<PdfStack style={[{ width: '85%', gap: '4pt' }]}>
								<Text style={styles.footerParagraph} wrap={false}>
									All involved parties hereby agree that they have familiarised
									themselves with the usage instructions (including crop
									registration, pest registration and registered dose rates),
									warnings and withholding periods as it relates to the above
									recommended product labels.
								</Text>
								<Text style={styles.footerParagraph} wrap={false}>
									Alle betrokke partye stem hiermee saam dat hulle hulself
									vergewis het van die gebruiksinstruksies (insluitend
									gewasregistrasie, plaagregistrasie en geregistreerde
									dosishoeveelhede), waarskuwings en onthoudingsperiodes soos
									dit met bogenoemde aanbevole produketikette verband hou.
								</Text>
							</PdfStack>
						</PdfRow>
					</View>
				</PdfContent>
			</Page>
		</Document>
	);
};
