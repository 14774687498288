// Core viewer
import {
	Box,
	Card,
	LinearProgress,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Worker, Viewer, ViewMode } from '@react-pdf-viewer/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import * as Icons from '@iconscout/react-unicons';
import { Button, IconButton } from 'features';
import {
	RenderCurrentScaleProps,
	ZoomMenuItemProps,
} from '@react-pdf-viewer/zoom';
import { ReactElement, useState } from 'react';
import { PrintMenuItemProps } from '@react-pdf-viewer/print';
import { EnterFullScreenMenuItemProps } from '@react-pdf-viewer/full-screen';
import { GoToPageMenuItemProps } from '@react-pdf-viewer/page-navigation';
import { DownloadMenuItemProps } from '@react-pdf-viewer/get-file';
import { useNavigate } from 'react-router-dom';

type CustomPdfViewerProps = {
	source: string | Blob;
	enableDownload: boolean;
};

const IconWithTooltip = ({
	icon,
	title,
	onClick,
}: {
	icon: keyof typeof Icons;
	title: string;
	onClick: () => void;
}) => {
	return (
		<Tooltip title={title}>
			<IconButton icon={icon} onClick={onClick} />
		</Tooltip>
	);
};

export const CustomPdfViewer = ({
	source,
	enableDownload,
}: CustomPdfViewerProps) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
		<Toolbar>
			{(slots: ToolbarSlot) => {
				const {
					CurrentPageInput,
					CurrentScale,
					GoToNextPage,
					GoToPreviousPage,
					NumberOfPages,
					ZoomIn,
					ZoomOut,
					Download,
					Print,
					EnterFullScreen,
				} = slots;

				return (
					<Box
						sx={{
							display: 'flex',
							padding: 1,
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Stack direction={'row'} alignItems={'center'}>
							<GoToPreviousPage>
								{({ onClick }: GoToPageMenuItemProps) => (
									<IconWithTooltip
										title="Previous Page"
										icon="UilAngleUp"
										onClick={onClick}
									/>
								)}
							</GoToPreviousPage>
							<Stack direction='row' justifyContent='center' alignItems='center'>
								<CurrentPageInput />
								<Typography>/ </Typography>
								<NumberOfPages />
							</Stack>
							<GoToNextPage>
								{({ onClick }: GoToPageMenuItemProps) => (
									<IconWithTooltip
										title="Next Page"
										icon="UilAngleDown"
										onClick={onClick}
									/>
								)}
							</GoToNextPage>
						</Stack>
						<Stack direction={'row'} alignItems={'center'}>
							<ZoomOut>
								{({ onClick }: ZoomMenuItemProps) => (
									<IconWithTooltip
										title="Zoom Out"
										icon="UilSearchMinus"
										onClick={onClick}
									/>
								)}
							</ZoomOut>
							<CurrentScale>
								{(props: RenderCurrentScaleProps) => (
									<Typography>{`${Math.round(props.scale * 100)}%`}</Typography>
								)}
							</CurrentScale>
							<ZoomIn>
								{({ onClick }: ZoomMenuItemProps) => (
									<IconWithTooltip
										title="Zoom In"
										icon="UilSearchPlus"
										onClick={onClick}
									/>
								)}
							</ZoomIn>
						</Stack>
						<Stack direction={'row'} alignItems={'center'}>
							<EnterFullScreen>
								{({ onClick }: EnterFullScreenMenuItemProps) => (
									<IconWithTooltip
										title="Fullscreen"
										icon="UilExpandAlt"
										onClick={onClick}
									/>
								)}
							</EnterFullScreen>
							{enableDownload && (
								<>
									<Download>
										{({ onClick }: DownloadMenuItemProps) => (
											<IconWithTooltip
												title="Download"
												icon="UilDownloadAlt"
												onClick={onClick}
											/>
										)}
									</Download>
									<Print>
										{({ onClick }: PrintMenuItemProps) => (
											<IconWithTooltip
												title="Print"
												icon="UilPrint"
												onClick={onClick}
											/>
										)}
									</Print>
								</>
							)}
						</Stack>
					</Box>
				);
			}}
		</Toolbar>
	);

	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar,
		sidebarTabs: (defaultTabs) => [defaultTabs[0]],
	});

	const fileUrl = source instanceof Blob ? URL.createObjectURL(source) : source;

	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	return (
		<Stack>
			<Button
				startIcon="UilArrowLeft"
				variant="contained"
				size="small"
				sx={{ width: '90px', ml: 4, mt: 1 }}
				onClick={() => navigate(-1)}
			>
				Back
			</Button>
			<Box sx={{ 
				p: isMobile ? 0.5 : 4, 
				height: loading ? '100vh' : 'auto', 
				pt: 1 
				}}
			>
				<Worker
					workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
				>
					<Viewer
						fileUrl={fileUrl}
						plugins={[defaultLayoutPluginInstance]}
						viewMode={ViewMode.SinglePage}
						renderLoader={(progress: number) => {
							if (progress === 100) setLoading(false);
							return (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										padding: '0%',
										width: '100%',
										height: '100%',
									}}
								>
									<LinearProgress variant="determinate" value={progress} />
								</Box>
							);
						}}
						renderError={(e) => {
							console.log(e);
							return (
								<Card
									sx={{
										boxSizing: 'border-box',
										display: 'flex',
										flexDirection: 'column',
										padding: 4,
										justifyContent: 'center',
										width: '100%',
										alignItems: 'center',
									}}
								>
									<Typography>
										An error occured while retrieving the file.
									</Typography>
									<Button
										variant="contained"
										label="Back"
										startIcon="UilArrowLeft"
										sx={{
											mt: 1,
										}}
										size="small"
										onClick={() => navigate(-1)}
									/>
								</Card>
							);
						}}
					/>
				</Worker>
			</Box>
		</Stack>
	);
};
